"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { downloadRepo } from "@/actions/github";
import { getStripe } from "@/actions/stripe/client";
import { checkoutWithStripe } from "@/actions/stripe/server";
import { useAccess } from "@/context/access-context";
import { Check, ChevronRight, Download } from "lucide-react";
import { toast } from "sonner";
import { useServerAction } from "zsa-react";

import { cn, formatPrice } from "@/lib/utils";
import { Button, buttonVariants } from "@/components/ui/button";
import { Icons } from "@/components/icons";

export default function RepoDownload({
  priceId,
  coupon,
  className,
  mode,
  showPrice = false,
  children,
  free = false,
  repo,
  owner,
}: {
  priceId?: string;
  coupon?: string;
  mode?: "payment" | "setup" | "subscription";
  className?: string;
  showPrice?: boolean;
  children?: React.ReactNode;
  free?: boolean;
  repo?: string;
  owner?: string;
}): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [priceInfo, setPriceInfo] = useState<{
    amount?: number;
    currency?: string;
  }>({});

  const { accessState, isLoading } = useAccess();
  const hasAccess = free || (priceId ? accessState[priceId]?.hasAccess : false);

  const { execute: executeDownloadRepo, isPending: isDownloading } =
    useServerAction(downloadRepo);

  useEffect(() => {
    if (!priceId) return;
    if (accessState[priceId]) {
      setPriceInfo({
        amount: accessState[priceId].amount!,
        currency: accessState[priceId].currency!,
      });
    }
  }, [accessState, priceId]);

  const handleClick = async () => {
    if (!priceId) return;
    setLoading(true);
    const [data, err] = await checkoutWithStripe({ priceId, coupon, mode });
    if (err) {
      toast.error(err.message);
      setLoading(false);
      return;
    }
    if (!data?.sessionId) {
      toast.error("Error creating checkout session " + data?.errorRedirect);
      setLoading(false);
      return;
    }

    const stripe = await getStripe();
    stripe?.redirectToCheckout({ sessionId: data?.sessionId });
    setLoading(false);
  };

  const handleDownload = async () => {
    if (!repo || !owner) {
      toast.error("Error occurred while downloading. Please try again.");
      return;
    }

    const [data, error] = await executeDownloadRepo({ owner, repo });

    if (error) {
      toast.error("Error occurred while downloading. Please try again.");
      console.error("error", error);
      return;
    }

    if (data.success && data.downloadUrl) {
      window.location.href = data.downloadUrl;
    } else {
      toast.error("Failed to get download URL. Please try again.");
    }
  };

  const isDisabled = showPrice
    ? loading || !priceInfo?.amount || !priceInfo?.currency
    : loading || isLoading;

  if (hasAccess && repo && owner) {
    return (
      <Button
        onClick={handleDownload}
        disabled={isDownloading}
        className={cn(
          buttonVariants({ variant: "rainbow", size: "lg" }),
          "w-full gap-2",
        )}
      >
        {isDownloading ? (
          <>
            <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            Downloading...
          </>
        ) : (
          <>
            Download
            <Download className="h-4 w-4" />
          </>
        )}
      </Button>
    );
  }

  if (hasAccess) {
    return (
      <Link href="/templates" className={className}>
        You have access
        <Check className="h-4 w-4" />
      </Link>
    );
  }

  return (
    <Button
      onClick={handleClick}
      disabled={isDisabled}
      className={cn(
        buttonVariants({ variant: "rainbow", size: "lg" }),
        "w-full gap-2",
      )}
    >
      {isDisabled ? (
        <>
          <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
          Loading...
        </>
      ) : (
        <>
          {children}
          {showPrice && priceInfo.amount && priceInfo.currency && (
            <>
              {" - "}
              {formatPrice(priceInfo.amount, {
                currency: priceInfo.currency,
              })}
            </>
          )}
          <ChevronRight className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
        </>
      )}
    </Button>
  );
}
